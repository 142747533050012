import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import jquery from "../../node_modules/jquery/src/jquery.js";
import barba from "@barba/core";
import LocomotiveScroll from 'locomotive-scroll';
import Swiper, { Navigation, Pagination, Autoplay, FreeMode, EffectFade } from 'swiper';
import jqueryZoom from 'jquery-zoom';
import fitty from 'fitty';

UIkit.use(Icons);

/**
 * Rende disponibili le librerie nello scope globale.
 * Alternativamente, spostare gli import in app.js
 */
window.UIkit = UIkit;
window.$ = jquery;
window.barba = barba;
window.LocomotiveScroll = LocomotiveScroll;
Swiper.use([Navigation, Pagination, Autoplay, FreeMode, EffectFade]);

window.Swiper = Swiper;
window.fitty = fitty;
window.jqueryZoom = jqueryZoom;


/**
 * Lazysizes
 */
import lazySizes from 'lazysizes';
lazySizes.cfg.init = false; // init manuale, v. sotto
lazySizes.cfg.loadMode = 1; // 1 = only load visible elements
window.lazySizes = lazySizes;
